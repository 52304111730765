import { Button, Icon, StyleProps } from "@chakra-ui/react";
import { FC } from "react";
import { BiCheckCircle, BiPlusCircle } from "react-icons/bi";

const FilterTag: FC<
StyleProps & {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  selected: boolean;
}> = ({ label, selected, value, onChange, ...styleProps }) => {
  return (
    <Button
      onClick={() => onChange(value)}
      // bg="white"
      size="sm"
      leftIcon={<Icon as={!selected ? BiPlusCircle : BiCheckCircle} />}
      variant={selected ? "solid" : "outline"}
      rounded={"full"}
      {...((!selected) && { colorScheme: "gray", bgColor: "#FFF" })}
      // colorScheme={selected ? "yellow" : "gray"}
      {...styleProps}
    >
      {label}
    </Button>
  );
};

export default FilterTag;
